import _ from "lodash/fp";
import React from "react";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { SEARCH } from "@truedat/core/routes";
import backgroundImage from "assets/background_truedat.png";
import LoginView from "./LoginView";

const mainWrapper = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  position: "absolute",
  left: 0,
  right: 0,
  backgroundImage: "url(" + backgroundImage + ")",
  backgroundSize: "100% 100%",
  backgroundPosition: "0px 10px",
  backgroundRepeat: "no-repeat"
};

const viewStyle = {
  width: "310px"
};

const resolveRoute = defaultRoute =>
  _.cond([
    [_.pathEq("state.from.pathname", "/"), () => defaultRoute],
    [_.has("state.from"), _.path("state.from")],
    [_.stubTrue, () => defaultRoute]
  ]);

const LoginOrRedirect = ({ token }) => {
  const location = useLocation();
  const currentTime = (new Date().getTime() + 1) / 1000;
  const validToken = token && jwt.decode(token).exp > currentTime;

  if (validToken) {
    const to = resolveRoute(SEARCH)(location);
    return <Redirect to={to} />;
  } else {
    return (
      <div style={mainWrapper}>
        <div style={viewStyle}>
          <LoginView />
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ authentication }) => ({
  token: authentication.token
});

export default connect(mapStateToProps)(LoginOrRedirect);
