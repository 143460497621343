/* eslint-disable no-undef */
import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import routes from "routes";
import { useAuthorized } from "@truedat/core/hooks";
import {
  getDashboardConfig,
  getQualityDashboardConfig
} from "@truedat/core/selectors";
import { DomainsLoader } from "@truedat/bg/taxonomy/components";
import { ConfigurationTokenLoader } from "@truedat/cx/components";
import DashboardMetabase from "../DashboardMetabase";

const qualityDashboardPermissions = ["view_quality_rule"];
const mainDashboardPermissions = ["view_dashboard"];

const DashboardRoutes = () => {
  const authorized_dashboards = useAuthorized("dashboards");
  const authorized_dq = useAuthorized("data_quality");
  const authorized = authorized_dashboards || authorized_dq;

  const dashboardConfig = useSelector(getDashboardConfig);
  const qualityDashboardConfig = useSelector(getQualityDashboardConfig);

  return authorized ? (
    <>
      {authorized_dashboards && !_.isNil(dashboardConfig) && (
        <Route
          exact
          path={routes.DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={dashboardConfig.external_id}
                dashboardId={dashboardConfig.content.dashboard_id}
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase
                action="viewDashboard"
                dashboardConfig={dashboardConfig}
              />
            </>
          )}
        />
      )}
      {authorized_dq && !_.isNil(qualityDashboardConfig) && (
        <Route
          exact
          path={routes.DQ_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                dashboardConfig={qualityDashboardConfig}
                externalId={qualityDashboardConfig.external_id}
                dashboardId={
                  qualityDashboardConfig.content.quality_dashboard_id
                }
              />
              <DomainsLoader actions={qualityDashboardPermissions} />
              <DashboardMetabase dashboardConfig={qualityDashboardConfig} />
            </>
          )}
        />
      )}
    </>
  ) : null;
};

export default DashboardRoutes;
