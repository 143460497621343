import React from "react";
import { Icon, Menu } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const url =
  "https://bluebay-my.sharepoint.com/:u:/p/tmayoh/ERFbOMRNM3VIqkgjVAqUFp8BcwqDE7FazZ7uHs3zyDzruw?e=ZJEHQR";

export const TaxonomyDiagram = () => {
  const sidebarVisible = useSelector(state => state.sidebarVisible);
  const { formatMessage } = useIntl();
  const title = formatMessage({
    id: "sidemenu.taxonomy_diagram",
    defaultMessage: "Domain Taxonomy Diagram"
  });
  return (
    <Menu.Item as="a" href={url} name="taxonomy_diagram" title={title}>
      <Icon size="large" name="linkify" />
      {sidebarVisible && title}
    </Menu.Item>
  );
};

export default TaxonomyDiagram;
