/* eslint-disable no-undef */
import React from "react";
import { connect } from "react-redux";
import ResponsiveEmbed from "react-responsive-embed";
import PropTypes from "prop-types";
import { UserDomainsFilter } from "@truedat/auth/users/components";
import { signConfiguration } from "@truedat/cx/routines";

export const DashboardMetabase = ({ configurationToken, dashboardConfig }) => {
  const {
    content: { metabase_url }
  } = dashboardConfig;

  const src = configurationToken
    ? `${metabase_url}/embed/dashboard/${configurationToken}#bordered=false&titled=true`
    : "";
  return (
    <>
      <UserDomainsFilter />
      {src ? <ResponsiveEmbed src={src} scrolling={"yes"} /> : null}
    </>
  );
};

const mapDispatchToProps = { signConfiguration };

const mapStateToProps = ({ configurationToken }) => ({
  configurationToken
});

DashboardMetabase.propTypes = {
  dashboardConfig: PropTypes.object,
  configurationToken: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMetabase);
