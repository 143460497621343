import _ from "lodash/fp";
import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";

const iconDecorator = field => (field > 0 ? <Icon name="check circle" /> : "");

const translateDecorator = text => <FormattedMessage id={text} />;

const dateDecorator = date => (
  <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />
);

const initialState = [
  { name: "name", sort: { name: "name.raw" }, width: 6 },
  {
    name: "domain_parents",
    header: "domain",
    fieldSelector: ({ domain_parents }) => {
      return _.reverse(domain_parents)
        .map(a => a.name)
        .join(" > ");
    },
    width: 4
  },
  {
    name: "status",
    sort: { name: "status" },
    fieldDecorator: field => translateDecorator(`concepts.status.${field}`),
    width: 1
  },
  {
    name: "rule_count",
    sort: { name: "rule_count" },
    fieldDecorator: iconDecorator,
    textAlign: "center",
    width: 1
  },
  {
    name: "link_count",
    sort: { name: "link_count" },
    fieldDecorator: iconDecorator,
    textAlign: "center",
    width: 1
  },
  {
    name: "last_change_at",
    sort: { name: "last_change_at" },
    fieldDecorator: dateDecorator,
    textAlign: "center",
    width: 2
  }
];

export const conceptsColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
