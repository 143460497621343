import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

export const OidcLoginButton = ({ url, color = "yellow" }) =>
  url ? (
    <a className={`ui secondary button ${color}`} href={url}>
      <FormattedMessage id="login.form.actions.oidc" />
    </a>
  ) : null;

OidcLoginButton.propTypes = {
  color: PropTypes.string,
  url: PropTypes.string
};

const mapStateToProps = ({ authMethods }) => ({
  url: authMethods ? _.flow(_.get("oidc"))(authMethods) : null
});

export default connect(mapStateToProps)(OidcLoginButton);
