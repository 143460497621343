import React, { useState } from "react";
import { AuthMethodsLoader, OidcLoginButton } from "@truedat/auth/components";
import truedatLogo from "assets/bluebay-login-and-top-logo.png";
import { Header, Image } from "semantic-ui-react";

import LoginForm from "./LoginForm";

const LoginHeader = () => (
  <Header
    inverted
    as="h1"
    icon
    textAlign="center"
    style={{ marginBottom: "50px" }}
  >
    <Header.Content>
      <Image src={truedatLogo} />
    </Header.Content>
  </Header>
);

const LoginView = () => {
  const [formVisible] = useState(false);
  return (
    <>
      <AuthMethodsLoader />
      <LoginHeader />
      <div style={{ textAlign: "center" }}>
        <OidcLoginButton />
      </div>
      <LoginForm isVisible={formVisible} />
    </>
  );
};

export default LoginView;
