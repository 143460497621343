export default {
  "filters.agrupacion": "Agrupación información",
  "filters.calculan": "Calculado por",
  "filters.confidencialidad": "Confidencialidad",
  "filters.criticidad": "Criticidad",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.dominio_informacion": "Dominio Información de Gestión",
  "filters.fase1": "DWH Fase I",
  "filters.functional_owner": "Functional Owner",
  "filters.idioma": "Idioma",
  "filters.naturaleza": "Naturaleza del dato",
  "filters.pais": "País",
  "filters.sensible": "Sensible",
  "filters.sujeto_gdpr": "Sujeto a GDPR",
  "filters.temporalidad": "Temporalidad",
  "filters.tipo": "Tipo de término",
  "filters._confidential": "Confidencial",
  "filters.gdpr": "GDPR",
  "filters.history": "Histórico",
  "filters.periodicity": "Periodicidad",
  "filters.scope": "Alcance",
  "filters.owner": "Data Owner",
  "filters.dq_periodicity": "Periodicidad",
  "filters.dq_principle": "Principio de calidad",
  "filters.class.raw": "Clase",
  "filters.class.raw.field": "Campo",
  "navigation.glossary.concepts": "Glosario" /*"Glosario de negocio",*/,
  "navigation.glossary.taxonomy": "Taxonomía",
  "navigation.dictionary.structures": "Catálogo" /*"Catálogo de datos",*/,
  "navigation.quality.rules": "Calidad" /*"Calidad del dato",*/,
  "navigation.quality.dashboard": "Dashboard de Calidad",
  "navigation.lineage.search": "Linaje",
  "navigation.ingest.ingests": "Peticiones de datos",

  // Taxonomy
  //"concepts.taxonomy": "Data Domains",
  //"filters.taxonomy": "Data Domains",
  //"navigation.glossary.taxonomy": "Data Domains",
  //"sidemenu.taxonomy": "Data Domains",

  // Concepts
  "concepts.actions.delete.body.without_content":
    "You are going to delete this Business Concept without linkage, related terms and quality rules",
  "concepts.actions.upload.tooltip": "Upload terms",
  "concepts.actions.download.tooltip": "Download terms",
  "concepts.bulkUpdate.success.content": "Updated {count} terms successfully",
  "concepts.edit.bulk.confirmation.content":
    "{concepts_count} terms will be updated. Are you sure?",
  "concepts.header.edit.bulk": "Edit Business Terms",
  "concepts.header.edit.bulk.concepts_count":
    'Selected terms: "{concepts_count}"',
  "concepts.header.manage": "Terms Management",
  "concepts.retrieved.results": "{count} terms found",
  "concepts.search.placeholder": "Search terms...",
  "concepts.search.results.empty": "No terms found",
  "concepts.subheader.view": "Query business terms",
  "concepts.upload.failed.header":
    "Error uploading file. No terms have been created.",
  "alert.uploadConcepts.failed.header":
    "Error uploading file. No terms have been created.",
  "concepts.upload.success.content": "Loaded {count} terms successfully",
  "deleteDomain.error.domain.existing.business.concept":
    "Domain containing active business terms can not be deleted",
  "domain.concept.children.count":
    "{counter} associated terms. This role can not be deleted.",
  "navigation.glossary.concepts": "Terms",
  "relations.actions.concept.delete.confirmation.content":
    "Link between terms will be deleted. Are you sure?",
  "tabs.se.concepts": "Terms",
  "updateDomain.error.business_concept.domain.error.existing.business_concept.name":
    "Terms with repeated name",

  "login.form.actions.auth0": "Acceso Auth0",
  "login.form.actions.oidc": "Login",
  "login.description": "Data Governance",

  "structure.lopd": "Sensibilidad GDPR",

  //Structure types

  "structure.type.Column.text": "Columna",
  "structure.type.Column.icon": "columns",

  "structure.type.column.text": "Columna",
  "structure.type.column.icon": "columns",

  "structure.type.NUMBER.text": "Columna",
  "structure.type.NUMBER.icon": "hashtag",

  "structure.type.smallint.text": "Columna",
  "structure.type.smallint.icon": "hashtag",

  "structure.type.decimal.text": "Columna",
  "structure.type.decimal.icon": "hashtag",

  "structure.type.bit.text": "Columna",
  "structure.type.bit.icon": "hashtag",

  "structure.type.Whole Number.text": "Columna",
  "structure.type.Whole Number.icon": "hashtag",

  "structure.type.CHAR.text": "Columna",
  "structure.type.CHAR.icon": "font",

  "structure.type.VARCHAR.text": "Columna",
  "structure.type.VARCHAR.icon": "font",

  "structure.type.VARCHAR2.text": "Columna",
  "structure.type.VARCHAR2.icon": "font",

  "structure.type.varchar.text": "Columna",
  "structure.type.varchar.icon": "font",

  "structure.type.Text.text": "Columna",
  "structure.type.Text.icon": "font",

  "structure.type.DATE.text": "Columna",
  "structure.type.DATE.icon": "calendar alternate outline",

  "structure.type.Date.text": "Columna",
  "structure.type.Date.icon": "calendar alternate outline",

  "structure.type.datetime2.text": "Columna",
  "structure.type.datetime2.icon": "clock outline",

  "structure.type.Table.text": "Tabla",
  "structure.type.Table.icon": "table",

  "structure.type.table.text": "Tabla 2",
  "structure.type.table.icon": "table",

  "structure.type.Database.text": "Base de datos",
  "structure.type.Database.icon": "database",

  "structure.type.database.text": "Base de datos",
  "structure.type.database.icon": "database",

  "structure.type.Schema.text": "Esquema",
  "structure.type.Schema.icon": "database",

  "structure.type.schema.text": "Esquema",
  "structure.type.schema.icon": "database",

  "structure.type.Report.text": "Reporte",
  "structure.type.Report.icon": "chart bar",
  "structure.type.Report.lineage.icon": "chart-bar",

  "structure.type.Carpeta.text": "Carpeta",
  "structure.type.Carpeta.icon": "folder",

  "structure.type.Folder.text": "Carpeta 2",
  "structure.type.Folder.icon": "folder",

  "structure.type.Documento.text": "Documento",
  "structure.type.Documento.icon": "file outline",
  "structure.type.Documento.lineage.icon": "file",

  "structure.type.Informe.text": "Informe",
  "structure.type.Informe.icon": "chart bar",
  "structure.type.Informe.lineage.icon": "chart-bar",

  "structure.type.Field.text": "Campo",
  "structure.type.Field.icon": "columns",

  "structure.type.BASE TABLE.text": "Tabla base",
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.DssTypeDocumentDefinition.text":
    "Definición de tipo de documento Dss",
  "structure.type.DssTypeDocumentDefinition.icon": "file outline",
  "structure.type.DssTypeDocumentDefinition.lineage.icon": "file",

  "structure.type.DssTypeReportDefinition.text":
    "Definición de tipo de informe Dss",
  "structure.type.DssTypeReportDefinition.icon": "chart bar",
  "structure.type.DssTypeReportDefinition.lineage.icon": "chart-bar",

  "structure.type.DssTypeFolder.text": "Carpeta de tipo de Dss",
  "structure.type.DssTypeFolder.icon": "folder",

  "structure.type.Calculated Table.text": "Tabla calculada",
  "structure.type.Calculated Table.icon": "table",

  "structure.type.USER_TABLE.text": "Tabla de usuario",
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.VIEW.text": "Vista",
  "structure.type.VIEW.icon": "table",

  "structure.type.DIRECTORY.text": "Directorio",
  "structure.type.DIRECTORY.icon": "folder",

  "structure.type.FILE.text": "Fichero",
  "structure.type.FILE.icon": "file outline",
  "structure.type.FILE.lineage.icon": "file",

  "structure.type.EXTERNAL_TABLE.text": "Tabla externa",
  "structure.type.EXTERNAL_TABLE.icon": "table",

  "quality.result_avg": "Calidad actual (%)",
  "quality.last_execution_at": "Última ejecución",

  //Business concepts relations
  "source.bc_father_of": "Concepto padre de",
  "target.bc_father_of": "Concepto hijo de",
  "source.bc_related_to": "Relacionado con",
  "target.bc_related_to": "Relacionado con",
  "source.bc_translated_to_spanish": "Se traduce a español como",
  "target.bc_translated_to_spanish": "Se traduce a inglés como",
  "source.bc_parent": "Concepto padre de",
  "target.bc_parent": "Concepto hijo de",

  //Parámetros de implementaciones
  "ruleImplementation.props.campo": "Campo",
  "ruleImplementation.props.campo.placeholder": "Selecciona el campo",

  "ruleImplementation.props.campo_maestro": "Campo maestro",
  "ruleImplementation.props.campo_maestro.placeholder":
    "Selecciona el campo maestro",

  // Ingests
  "alert.ingestAction.failed.header": "Error creando petición de datos",
  "alert.upload.failed.content":
    "Error al leer las peticiones de datos, no se ha realizado ninguna inserción. Error en la petición de datos [{row}], {error}",
  "alert.upload.success.content":
    "Se han insertado correctamente {count} peticiones de datos",
  "ingest.error.existing.ingest":
    "({text}) Ya existe una petición de datos con el nombre indicado",
  "ingest.events.add_resource_field": "asoció un dato a la petición de datos",
  "ingest.events.delete_resource_field":
    "desasoció un dato de la petición de datos",
  "ingest.events.ingest_deprecated": "archivó la petición de datos",
  "ingest.relatedTo.ingest": "Relacionado con petición de datos",
  "ingestLink.actions.delete.confirmation.content":
    "Se eliminará la vinculación entre el petición de datos y la estructura. ¿Estás seguro?",
  "ingestRelations.relatedDataIngest": "Peticiones de datos relacionadas",
  "ingests.actions.create": "Nueva Petición de datos",
  "ingests.actions.delete.confirmation.content":
    "Se eliminará esta petición de datos. ¿Estás seguro?",
  "ingests.actions.delete.confirmation.header": "Borrar petición de datos",
  "ingests.actions.deprecate.confirmation.content":
    "Se archivará este petición de datos. ¿Estás seguro?",
  "ingests.actions.deprecate.confirmation.header": "Archivar petición de datos",
  "ingests.actions.upload.confirmation.header":
    "Subir fichero peticiones de datos",
  "ingests.crumbs.top": "Peticiones de datos",
  "ingests.header": "Peticiones de datos",
  "ingests.header.duplicate": "Duplicar Petición de datos",
  "ingests.header.edit": "Editar Petición de datos",
  "ingests.props.ingest_id": "Id petición de datos",
  "ingests.props.name": "Petición de datos",
  "ingests.search.placeholder": "Buscar peticiones de datos...",
  "ingests.search.results.empty":
    "No se han encontrado peticiones de datos para esta búsqueda",
  "ingests.subheader": "Consultar y gestionar peticiones de datos",
  "navigation.ingest": "Petición de datos",
  "navigation.ingests": "Peticiones de datos",
  "parentIngest.selector.label": "Petición de datos padre",
  "parentIngest.selector.placeholder": "Selecciona una petición de datos...",
  "tabs.ie.ingest": "Petición de datos",
  "tabs.ie.relationsIngests": "Peticiones de datos relacionadas",
  "tabs.se.ingests": "Peticiones de datos",
  "ingest.relations.actions.data_structure.delete.confirmation.content":
    "Se eliminará la vinculación entre la petición de datos y la estructura. ¿Estás seguro?",
  "filters.tipo_ingesta": "Tipo Petición",
  "ingests.tabs.published": "Publicadas",
  "ingest.events.relation_created": "Nueva relación",
  "ingest.events.relation_deleted": "Relación eliminada",

  "appSetup.welcome.message": "Bienvenido a Truedat",
  "appSetup.configuration.message":
    "Parece que todo se ha instalado correctamente. Vamos a comenzar a configurar la aplicación para poder empezar a usarla tras unos sencillos pasos",
  "appSetup.begin.button": "Comenzar",

  "filtersGrid.field.modifier.cast_as_date": "Convertir a fecha",
  "filtersGrid.field.modifier.cast_as_timestamp": "Convertir a timestamp",
  "filtersGrid.field.modifier.cast_as_date.format": "Formato de fecha",
  "filtersGrid.field.modifier.to_string": "Convertir a texto",
  "filtersGrid.field.modifier.substring": "Subcadena",
  "filtersGrid.field.modifier.substring.start": "Posición de inicio",
  "filtersGrid.field.modifier.substring.end": "Posición de fin",
  "filtersGrid.field.modifier.concat": "Concatenatenar",
  "filtersGrid.field.modifier.concat.with_fields": "Campos"
};
